@import "../../../styles/config";

.high{
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.1);
    margin: 10px;
    border-radius: 5px;
    &__header{
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 10px;
        letter-spacing: 0.2px;
        border-radius: 5px;
        background-color: var(--dark);
        color: var(--bg);
        svg{
            font-size: 1.5rem;
        }
        &__name{
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: $font-semibold;
        }
        &__info{
            font-size: 0.9rem;
        }
        &__score{
            background: rgba(0, 0, 0, 0.16);
            width: calc(100% + 20px);
            text-align: center;
            margin: 10px -10px -10px -10px;
            padding: 10px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            &__team{
                font-weight: 700;
            }
        }
    }
    &__withImages{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    &__body{
        div{
            padding-bottom: 56.25% !important;
            iframe{
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px; 
            }
        }
    }
}

.medium{
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.1);
    margin: 10px;
    border-radius: 5px; 
    padding: 10px;
    background-color: var(--navApp);
    @media screen and (min-width: $max-770){
        background-color: var(--bg); 
    }
    &__header{
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        &__name{
            text-transform: uppercase;
            font-weight: $font-semibold;
            letter-spacing: 0.2px;
            color: var(--text);
            span{
                margin-right: 10px;
                color: var(--text);
            }
        }
        &__time{
            font-size: 0.8rem;
            font-weight: $font-semibold;
            color: $color-gray;
        }
        &__info{
            color: $color-gray;
            span{
                text-transform: uppercase;
                font-size: 0.8rem;
                letter-spacing: 0.2px;
            }
        }
    }
    &__body{
        border-top: 1px solid var(--light);
        padding-top: 10px;
        div{
            padding-bottom: 56.25% !important;
            iframe{
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px; 
            }
        }
    }
}

.low{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    color: $color-gray;
    svg{
        font-size: 1.5rem;
    }
    p{
        margin: 5px 0;
        font-weight: $font-semibold;
        font-size: 0.9rem;
        &:last-child{
            font-size: 0.8rem;
            font-weight: inherit;
        }
    }
    &__name{
        width: 100%; 
        text-align: center; 
        border-bottom: 1px solid var(--light); 
        line-height: 0.1em;
        margin: 10px 0 20px; 
        span{
            // background:#fff; 
            padding:0 10px; 
            text-transform: uppercase;
            letter-spacing: 0.2px;
        }
    }
}

.multiLiveScore{
    text-align: center;
    font-size: 0.8rem;
    color: $color-gray;
    margin-bottom: 25px !important;
    &__name{
        width: 100%; 
        text-align: center; 
        line-height: 0.1em;
        margin: 10px 0 20px; 
        span{
            // background:#fff; 
            padding:0 10px; 
            text-transform: uppercase;
            letter-spacing: 0.2px;
        }
    }
}
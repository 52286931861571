@import "../../styles/config";

.container{
    margin-top: 55px;
    width: 100%;
    top: 50px;
    z-index: 1;
    background-color: var(--bg);
    display: none;
    // position: fixed;
    &__iframe{
        margin-top: 0;
    }
    // min-height: 100vh;
    @media screen and (min-width: $max-770){
        border-radius: 5px;
        min-height: inherit;
        background-color: var(--bg-desktop-item);
    }
    @media screen and (max-width: $max-tablet){
        // height: 500px;
        overflow-y: scroll;
        box-shadow: 1px 6px 7px 0px rgba(0,0,0,0.2);
    }
   &__header{
       padding: 10px;
       display: flex;
       justify-content: space-between;
       align-items: center;
       color: var(--text);
       @media screen and (max-width: $max-tablet){
        position: sticky;
        top: 0;
        background: var(--bg);
       }
       &__info{
           display: flex;
           flex-direction: column;
           font-weight: $font-semibold;
           &__name{
                text-transform: uppercase;
                letter-spacing: 0.2px;
           }
           &__disclaimer{
               color: var(--link);
               font-size: 0.8rem;
           }
       }
       &__close{
           color: $color-gray;
           font-size: 1.5rem;
           cursor: pointer;
       }
   }
}

.open_container{
    display: block;
}

.item{
    border-bottom: 1px solid var(--light);
    cursor: pointer;
    &__header{
        background-color: var(--light);
        display: flex;
        justify-content: space-around;
        padding: 5px 10px;
        font-weight: $font-semibold;
        font-size: 0.8rem;
        color: var(--text);
        @media screen and (min-width: $max-770){
            background-color: var(--middleLight);
        }
        &__team{
            display: flex;
            justify-content: space-between;
            flex: 2;
            &__name{
                text-transform: uppercase;
                letter-spacing: 0.2px;
            }
        }
        &__time{
            padding: 0px 20px;
            color: var(--link);
        }
    }
    &__body{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        min-height: 60px;
        color: var(--text);
        &__goals{
            list-style: none;
            font-size: 0.8rem;
            margin: 0;
            padding: 0;
            width: 40%;
            &:last-child{
                text-align: right;
            }
            li{
                span{
                    margin-right: 5px;
                    color: var(--text);
                }
                b{
                    font-weight: $font-semibold;
                    color: $color-gray;
                }
            }
        }
    }
}
$gutter: 30px;
$container-gutter-mobile: $gutter;
$container-gutter-tablet: 50px;
$container-gutter-desktop: 70px;

// Fonts
$font-text: 'Titillium Web', verdana, sans-serif;
$font-size: 1rem;
$font-size-max: 2rem;
$font-size-medium: 1.5rem;
$font-size-min: 0.8rem;
$font-size-nano: 0.7rem;
$line-height: 1.5;
$font-regular: 400;
$font-semibold: 700;
$font-bold: 500;

// Colors
$color-white: #ffff;
$color-light: #f3f3f3;
$color-middle-light: #ebebeb;
$color-middle-gray: #c7c7c7;
$color-gray-desktop: #c7c7c7;
$color-gray: #b1b1b1;
$color-dark: #0b0b0b;
$color-green: #46a546;
$color-red: #d60b13;
$color-red-pale: #fd4349;
$color-light-red: #d60b1215;
$color-red-opacity: #f7ced0;
$color-bg: #ffff;
$color-bg-desktop: #f3f3f3;
$color-bg-desktop-item: #ffff;
$color-text: #000;
$color-loading: #e8e8e8;
$color-header: #d60b13;
$color-navApp: #ffff;
$color-teamLogo: #ffff;
$color-yellow: #FFC700;
$color-bg-tuto: ghostwhite;

$color-deg: 255,255,255;

$searchIcon: url('/assets/images/search.svg');


// Custom break point (Min)
$min-1800: 1800px;
$min-1440: 1440px;
$min-1280: 1280px;
$min-1200: 1200px;
$min-1080: 1080px;
$min-1024: 1024px;
$min-960: 960px;
$min-840: 840px;
$min-768: 768px;
$min-720: 720px;
$min-640: 640px;
$min-600: 600px;
$min-560: 560px;
$min-540: 540px;
$min-480: 480px;
$min-420: 420px;
$min-375: 375px;
$min-360: 360px;

// Custom break points (Max)
$max-1280: 1279px;
$max-1200: 1199px;
$max-1080: 1079px;
$max-1024: 1023px;
$max-960: 959px;
$max-840: 839px;
$max-770: 770px;
$max-768: 767px;
$max-720: 719px;
$max-640: 639px;
$max-600: 599px;
$max-540: 539px;
$max-480: 479px;
$max-420: 419px;
$max-375: 374px;
$max-360: 359px;

// Predefined breakpoints
$min-mobile: $min-540;
// $min-mobile: "screen and (max-width: " + $min-420 + ")";
$min-tablet: $min-720;
$max-tablet: $max-770;
$min-desktop:"screen and (max-width: " + $min-1080+ ")";
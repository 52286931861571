@import "../../styles/config";

.container{
  color: var(--text);
  padding: 15px;
  background-color: var(--light);
  min-height: 100vh;
  max-width: 800px;
  margin: 0 auto;
  &__header{
    display: flex;
    @media screen and (max-width: $max-770){
      flex-wrap: wrap;
    }
    &__item{
      text-align: center;
      padding: 15px;
      width: 100%;
      background-color: var(--bg);
      box-shadow: 0 0 9px 0 rgba(0,0,0,0.1);
      color: var(--text);
      margin-left: 2%;
      margin-bottom: 15px;
      @media screen and (max-width: $max-770){
        // margin-left: 15px;
        width: 49%;
        &:nth-child(odd){
          margin-left: 0;
        }
      }
      &:first-child{
        margin-left: 0;
      }
      border-radius: 4px;
      &__number{
        font-size: 2rem;
        font-weight: 700;
      }
      svg{
        padding: 5px;
        border-radius: 100%;
        background-color: var(--light);
        color: var(--red-pale);
        font-size: 35px;
      }
    }
  }
  &__graph{
    &__wrap{
      height: 300px;
      @media screen and (max-width: $max-770){
        height: 200px;
      }
    }
    &__iframe{
      &__open{
        display: visible;
      }
      &__closed{
        display: none;
      }
    }
    &__match{
      span{
        font-weight: 500;
      }
      margin-bottom: 25px;
    }
    margin-top: 15px;
    padding: 15px;
    background-color: var(--bg);
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.1);
    color: var(--text);
    border-radius: 4px;
    h4{
      margin-top: 0;
    }
  }
  &__button{
    display: inline;
  }
}
@import "../../styles/config";

.container{
  display: flex;
  width: 100%;
  color: white;
  height: 50px;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  align-content: center;
  span{
    color: white;
  }
}
@import "styles/config";

.container{
    height: 55px;
    border-bottom: 1px solid var(--middleLight);
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    overflow-x: auto;
    background-color: var(--bg);
    z-index: 10;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    @media screen and (min-width: $max-770){
        background-color: var(--bg-desktop-item);
        padding-left: 10px;
        padding-right: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        position: sticky;
        top: 50px;
    }
    li{
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        margin-right: 5px;
        justify-content: center;
        align-items: center;
        font-weight: $font-semibold;
        color: var(--gray);
        text-transform: uppercase;
        cursor: pointer;
        span{
            height: inherit;
        }
    }
    &__live{
        margin-right: 20px;
        span{
            color: var(--link);
            &:first-child{
                font-size: 1.5rem;
                margin-top: 5px;
            }
            &:last-child{
                font-size: 0.7rem;
                margin-top: -10px;
                margin-bottom: 5px;
            }
        }
    }
    &__liveDisable{
        span{
            color: var(--gray);
        }
    }
    &__active{
        color: var(--text) !important;
    }
    &__calendar{
        font-size: 1.5rem;
        span{
            font-size: 0.7rem;
        }
    }
}
@import "../../styles/config";

.container{
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    flex-direction: column;
    color: var(--text);
    @media screen and (max-width: $max-tablet){
        margin-top: 50px;
    }
    svg{
        font-size: 4rem;
        margin-bottom: 15px;
        color: var(--text);
    }
}
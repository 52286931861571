@import "../../styles/config";

.container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 50px;
    background-color: var(--bg);
    @media screen and (min-width: $max-770){
        background-color: var(--bg-desktop-item);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        &__tooltip{
            margin: 0;
            padding-bottom: 20px;
        }
    }
    &__switchMatch{
        color: var(--link);
        margin: 10px;
        cursor: pointer;
    }
    &__matchNav{
        display: flex;
        background-color: var(--light);
        margin-top: 0;
        justify-content: space-between;
        @media screen and (min-width: $max-770){
            background-color: var(--middleLight);
        }
        &__actions{
            display: flex;
            align-items: center;
        }
        &__menu{
            color: $color-gray;
            display: flex;
            list-style: none;
            padding: 10px;
            text-transform: uppercase;
            letter-spacing: 0.2px;
            font-weight: $font-semibold;
            margin: 0;
            li{
                cursor: pointer;
                color: $color-gray;
                &:nth-child(2), &:nth-child(3){
                    &:before{
                        content: '/';
                        margin-left: 5px;
                        margin-right: 5px;
                        font-weight: normal;
                        color: $color-gray;
                    }
                }
            }
            &__active{
                color: var(--link) !important;
            }
        }
        &__status{
            background-color: gray;
            color: white;
            border-radius: 9px;
            padding: 0px 9px;
            margin: 11px 10px;
            font-size: 0.8rem;
            font-weight: bold;
        }
    }
    &__match{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        color: var(--text);
        svg{
            font-size: 5rem;
        }
        button{
            text-transform: inherit;
            margin-top: 20px;
            svg{
                font-size: 1rem;
            }
        }
        &__disclaimer{
            font-weight: $font-semibold;
            color: $color-gray;
            font-size: 0.9rem;
            margin-top: 5px;
        }
    }
    &__goTop{
        position: fixed;
        bottom: 20px;
        right: 15px;
        border-radius: 100%;
        height: 55px;
        width: 55px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        transition: opacity 0.2s ease-in;
        font-size: 1.5rem;
        background-color: var(--light);
        bottom: 60;
        color: var(--text);
        @media screen and (min-width: $max-770){
            display: none;
        }
    }
    &__tooltip{
        text-align: center;
        padding-bottom: 100px;
    }
    &__svg{
        text-align: center;
        margin: auto;
        font-size: 5rem;        
        margin-top: 20px;
        @media screen and (min-width: $max-770){
            margin-bottom: 20px;
        }
    }
    &__matchFacts{
        position: relative;
        // padding-bottom: 45.25%;
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
    }
    &__teamsTable{
        margin-top: 20px;
        color: var(--text);
        &__teams{
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            &__subs{
                font-weight: $font-semibold;
                padding: 15px 0 5px;
                margin-bottom: 5px;
                border-bottom: var(--text) 2px solid;
            }
            &__team{
                flex: 1;
                padding: 0 15px;
                &:last-child{
                    border-left: 2px dotted $color-gray;
                }
                p{
                    margin: 0;
                    font-size: 0.9rem;
                    text-align: left !important;
                    display: flex;
                    align-items: baseline;
                    b{
                        width: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}
@import "./fonts.scss";

@-ms-viewport {
    width: device-width;
  }
  
  @-o-viewport {
    width: device-width;
  }
  
  @viewport {
    width: device-width;
  }
  
  html {
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    // overflow-y: scroll;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    // background-color: var(--bg);
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  body {
    position: relative;
    margin: 0;
    font-family: $font-text;
    font-weight: $font-regular;
    counter-reset: heading-counter;
    text-size-adjust: 100%;
    background-color: var(--bg);
    @media screen and (min-width: $max-770){
      background-color: var(--bg-desktop);
    }
  }
  
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  a{
    text-decoration: none;
    color: $color-dark;
  }

  button{
    background: transparent;
    outline: none;
    border: none;
    font-size: $font-size;
    text-transform: uppercase;
}

.notification-container-mobile-top{
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  width: 100%;
}

.notification-container-top-center{
  width: 100%;
  top: 0;
  right: 0;
}

.notification-container-mobile-bottom{
  bottom: 35px !important;
  right: 0 !important;
  left: 0 !important;
  width: 100%;
  margin-bottom: 0 !important;
}

.notification-container-bottom-center{
  width: 100%;
  bottom: 0;
  right: 0;
}


.swiper-pagination-bullet-active{
  background: var(--link);
}
@import "./config.scss";
@import 'css-vars/css-vars';
$css-vars-use-native: true;

@include css-vars((
  --red: $color-red,
  --bg: $color-bg,
  --bg-desktop: $color-bg-desktop,
  --bg-desktop-item: $color-bg-desktop-item,
  --text: $color-text,
  --light: $color-light,
  --link: $color-red,
  --deg: $color-deg,
  --loading: $color-loading,
  --header: $color-header,
  --navApp: $color-navApp,
  --middleLight: $color-middle-light,
  --middleGray : $color-middle-gray,
  --gray : $color-gray,
  --dark : $color-dark,
  --red-pale: $color-red-pale,
  --teamLogo: $color-teamLogo,
  --grayDesktop: $color-gray-desktop,
  --redOpacity: $color-red-opacity,
  --bg-tuto: $color-bg-tuto,
  --searchIcon: $searchIcon,
));

  
@import "./base";


/***********************************************************************************
 ***********************             SWIPER                     ********************
 ***********************************************************************************
 */
.swiper-button-next,
.swiper-button-prev{
    opacity: 0.6;
}

.swiper-button-next{
    background-image: url('/assets/images/arrow-next.svg');
}

.swiper-button-prev{
    background-image: url('/assets/images/arrow-prev.svg');
}

.swiper-button-disabled{
    opacity: 0.2;
}

.swiper-relatedArticles{
  margin-top: 5px;
  overflow: hidden;
  .swiper-pagination-related,
  .swiper-pagination-related-default{
      @media screen and (min-width: $max-770){
          padding-left: 10px;
      }
      .swiper-pagination-bullet{
          background: var(--link);
          width: 30px;
          height: 5px;
          border-radius: 0;
      }
  }
  .swiper-pagination-related{
      .swiper-pagination-bullet-active{
          animation: reveal-bar 5s ease-in;
          background: linear-gradient(to right, var(--link) 50%, var(--redOpacity) 50%);
          background-size: 200% 100%;
      }
  }
  .swiper-pagination-related-default{
      .swiper-pagination-bullet{
          margin: 0 4px;
      }
  }
  .swiper-slide{
      width: 100% !important;
      display: flex;
      flex-direction: column;
      padding-bottom: 5px;
  }
}

@keyframes reveal-bar {
  from {
      background-position:right bottom;
  }
  to {
      background-position:left bottom;
  }
}



/***********************************************************************************
 ***********************             RANKINGS                   ********************
 ***********************************************************************************
 */
.matchs{
    .header{
        display: none;
    }
    thead.result{
        display: none;
    }
    .ranking,
    .result,
    .annonce{
        width: 100%;
        border-spacing: 0;
        color: var(--text);
        thead{
            td{
                color: $color-middle-gray;
                font-size: 0.8rem;
            }
        }
        th, td {
            // border-bottom: 1px solid var(--light);
            height: 36px;
            padding-right: 10px;
            padding-left: 10px;
        }
        tbody{
            .team{
                font-weight: $font-semibold;
            }
            .points{
                font-weight: $font-semibold; 
            }
            .date,
            .match,
            .score{
                font-size: 0.9rem;
            }
            .score{
                white-space: nowrap;
            }
            .match{
                font-weight: $font-semibold;
                width: 100%;
                padding-left: 5px;
            }
            tr{
                &:nth-child(even) {
                    background: var(--light);
                }
                display: none;
                &:nth-child(-n+3){
                    display: table-row;
                }
            }
        }
    }
}

.sportResults{
    padding: 8px 10px;
    background-color: var(--bg);
    // min-height: 100vh;
    @media screen and (min-width: $max-770){
        min-height: inherit;
    }
    .header{
        display: none;
    }
    .ranking,
    .result,
    .annonce{
        width: 100%;
        border-spacing: 0;
        thead{
            tr {
                &::before{
                    content: "pos";
                    color: $color-middle-gray;
                    font-size: 0.9rem;
                    height: 40px;
                    display: block;
                    line-height: 37px;
                    border-bottom: 1px solid var(--light);
                }
            }
            td{
                color: $color-middle-gray;
                font-size: 0.9rem;
            }
        }
        th, td {
            border-bottom: 1px solid var(--light);
            height: 40px;
        }
        tr{
            &:nth-child(even) {
                background: var(--light);
            }
        }
        tbody{
            counter-reset: line-number;
            tr{
                counter-increment: line-number;
                &::before{
                    content: counter(line-number)". ";
                    padding-left: 4px;
                    height: 40px;
                    display: block;
                    line-height: 37px;
                }
            }
            color: var(--text);
            .team{
                font-weight: $font-semibold;
            }
            .points{
                font-weight: $font-semibold; 
            }
            .date,
            .match,
            .score{
                font-size: 0.9rem;
            }
            .match{
                font-weight: $font-semibold;
            }
        }
    }
}



/***********************************************************************************
 ***********************             LOADER                     ********************
 ***********************************************************************************
 */
.loading-container{
    display: flex;
    justify-content: center;
    width: 100%;
    .load,
    .load-small {
        width: 150px;
        animation: loading 2s linear infinite;
        position: relative;
        
        .loading-inner {
            stroke: {
                dashoffset: 0;
                dasharray: 300;
                width: 4;
                miterlimit: 10;
                linecap: round;
            }
            animation: loading-circle 2s linear infinite;
            stroke: var(--link);
            fill: transparent;
        }
    }
    .load-small{
        width: 50px;
        .loading-inner {
            stroke: {
                width: 10;
            }
        }
    }
}

  
@keyframes loading {
    0% { 
        transform: rotate(0); 
    }
    
    100% { 
        transform: rotate(360deg);
    }
}

@keyframes loading-circle {
    0% { 
        stroke-dashoffset: 0
    }
    
    100% { 
        stroke-dashoffset: -600;
    }
}

/***********************************************************************************
 ***********************             CALENDAR                   ********************
 ***********************************************************************************
 */
.react-calendar{
    position: fixed;
    z-index: 10;
    top: 105px;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--middleLight);
    color: var(--text);
    text-decoration: none;
    font-family: $font-text;
    background-color: var(--bg);
    @media screen and (min-width: $max-770){
        position: sticky;
        top: 50px;
        z-index: 2;
    }
    .react-calendar__navigation__label,
    .react-calendar__navigation__arrow,
    .react-calendar__month-view__days__day{
        color: var(--text);
    }
    abbr{
        text-decoration: none;
    }
    .react-calendar__navigation__label,
    .react-calendar__year-view__months__month,
    .react-calendar__decade-view__years__year{
        font-size: 0.9rem;
        font-weight: $font-semibold;
        letter-spacing: 0.2px;
    }
    .react-calendar__tile--now{
        background: var(--red-pale);
        color: var(--bg);
    }
    .react-calendar__tile--active,
    .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus,
    .react-calendar__tile--hasActive{
        background: var(--link);
        color: var(--bg);
    }
    .react-calendar__navigation button{
        font-weight: $font-semibold;
    }
    .react-calendar__navigation{
        margin-bottom: 0px;
    }
    .react-calendar__navigation button[disabled]{
        background-color: transparent;
    }
}

/***********************************************************************************
 ***********************             ARTICLE                    ********************
 ***********************************************************************************
 */
.presentation{
  display: block;
  margin-bottom: 10px;
  .classgras{
      font-weight: $font-semibold;
  }
}

.ital{
  font-style: italic;
}

.article_error{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  color: var(--text);
  svg{
      font-size: 4rem;
      margin-bottom: 15px;
  }
  @media screen and (min-width: $max-770){
      justify-content: start;  
  }
}

.vpl_inline{
  margin: 0 !important;
}

.inlineLinks{
  padding: 10px 0;
  border-radius: 0px !important;
  border-top: 2px solid $color-middle-light;
  border-bottom: 2px solid $color-middle-light;
}

.exergue{
  color: var(--gray);
  font-weight: lighter;
  font-size: 1.6rem;
  font-style: italic;
  &::before{
      content: "\00AB"
  }
  &::after{
    content: "\00BB"
  }
}

/***********************************************************************************
 ***********************             FEEDBACK                   ********************
 ***********************************************************************************
 */
.feedbackButton{
    position: fixed;
    top: 170px;
    right: 15px;
    z-index: 1000;
    opacity: 1 !important;
    background-color: $color-white !important;
  }

/***********************************************************************************
 ***********************             ADMIN                      ********************
 ***********************************************************************************
 */
.ReactTable{
    border-radius: 5px;
    .rt-table{
        border-radius: 5px;
        .rt-td{
            margin: auto;
            text-align: center;
            svg{
                opacity: 0.3;
            }
        }
        .priority{
            svg{
                opacity: 1;
            }
        }
        .rt-resizable-header-content{
            font-weight: $font-semibold;
            font-size: 0.8rem;
            text-transform: uppercase;  
            letter-spacing: 0.2px;
            opacity: 0.8;
        }
        
        .rt-tr{
            cursor: pointer;
        }
        .-headerGroups{
            .rt-th{
                font-weight: $font-semibold;
                text-transform: uppercase;
                letter-spacing: 0.2px;
                font-size: 0.9rem;
                &:first-child{
                    background-color: $color-middle-light;
                }
            }
        }
        
        .left-align{
            text-align: left; 
        }
    }
    .-pagination{
        button{
            text-transform: capitalize;
        }
    }
    .bolder{
        font-weight: $font-semibold;
    }
}


/***********************************************************************************
 ***********************             TUTO                       ********************
 ***********************************************************************************
 */
.tutorial * {
	transform-origin: 50% 50%;
	perspective: 100px;
}

.tutorial{
    background-color: var(--bg-tuto);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 15px;
    .tutorial-title{
        font-weight: $font-semibold;
        text-align: center;
        font-size: 1.2rem;
        margin-top: 15px;
        color: var(--text);
    }
    .finger,
    .finger-second,
    .dnd-y-finger,
    .dnd-x-finger,
    .hold-finger,
    .dnd-finger {
        display: inline-block;
        width: 100px;
        margin: 3% auto;
    }
    .hand-hold,
    .hand-tap{
        fill: var(--bg);
        stroke: var(--text);
        stroke-width: 4px;
        stroke-linecap: round;
        stroke-linejoin: round;
        animation: tap 1.25s ease-out backwards;
        animation-iteration-count:infinite;
    }
    .hand-x {
        animation: swipe-x 1.25s ease-in-out backwards;
    }
    .hand-y{
        animation: swipe-y 1.25s ease-in-out backwards;
    }
    .hand-y,
    .hand-x {
        animation-iteration-count:infinite;
        fill: var(--bg);
        stroke: var(--text);
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    .arrow-up, 
    .arrow-down,
    .line-vertical,
    .arrow-left, 
    .arrow-right,
    .line-horizontal{
        fill: transparent;
        stroke:  var(--text);
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    .hand-hold {
        animation: hold 1.25s ease-out backwards;
        animation-iteration-count:infinite;
    }
    .hold-1,
    .hold-2, 
    .tap-1{
        fill: transparent;
        stroke: var(--text);
        stroke-width: 3px;
        stroke-linecap: round;
        stroke-linejoin: round;
    }
    .tap-1{
        animation: tap-circle 1.25s ease-out backwards;
        animation-iteration-count:infinite;
    }
    .swipe-vertical {
        opacity: .75;
    }
    .tutorial-buttons{
        position: absolute;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        button{
            text-transform: inherit;
        }
        p{
            text-decoration: underline;
            text-align: center;
            color: var(--gray);
            margin-bottom: 30px;
        }
    }
    p{
        color: var(--text)
    }
}

.tutorial-addTopic{
    .swiper-pagination-bullets{
        bottom: 30px;
    }
    .first{
        height: 400px;
        p{
            text-align: center;
        }
        .feed{
            background-color: var(--bg);
            border-radius: 5px;
            position: relative;
            width: 94%;
            margin: auto;
            margin-top: 45px;
            .feed-card{
                height: 105px;
                border-radius: 5px;
                box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.1);
                padding: 10px;
                .feed-card-body{
                    display: flex;
                    .feed-card-body-text{
                        flex: 2;
                        margin-right: 15px;
                       .feed-card-body-text-line{
                            height: 15px;
                            width: 100%;
                            background-color: var(--light);
                            margin-bottom: 10px;
                       } 
                    }
                    .feed-card-body-img{
                        flex: 1;
                        background-color: var(--light);
                        height: 80px;
                        border-radius: 5px;
                    }
                }
                .feed-card-footer{
                    margin-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .feed-card-footer-date{
                        width: 50px;
                        height: 10px;
                        background-color: var(--light);
                    }
                    svg{
                        color: var(--gray);
                    }
                }
            }
        }
    }
    .second{
        margin-top: 50px;
        height: 400px;
        .addTopic-modal{
            background-color: var(--bg);
            padding: 15px;
          .addTopic-modal-item{
              display: flex;
              align-items: center;
              color: var(--text);
              margin-bottom: 15px;
              svg{
                margin-right: 10px;
              }
              &:last-child{
                margin-bottom: 0;
              }
          }  
        }
    }
    .swiper-slide-active{
        .addTopic-modal{
            width: 94%;
            margin: auto;
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            animation: modal 1s ease-in;
        }
        .finger-second{
            position: absolute;
            left: 115px;
            bottom: 255px;
            animation: reveal-second 2s ease-in;
        }
        .second-msg{
            position: absolute;
            bottom: 185px;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            animation: setOpacity 2s ease-in;   
        }
    }
    .finger{
        position: absolute;
        right: -36px;
        bottom: -65px;
        animation: reveal-opacity 1s ease-in;
    }
    .swiper-pagination-bullet{
        width: 10px;
        height: 10px;
        margin: 0 3px;
        background: var(--link);
    }
}

.tutorial-rankings{
    position: relative;
    .rankings-img{
        margin-top: 30px;
    }
    .finger{
        position: absolute;
        bottom: 98px;
        right: -31px;
        animation: reveal-finger-rankings 1s ease-in;
    }
    p{
        text-align: center;
    }
    .add-ranking{
        padding: 0 15px;
        margin-top: 20px;
        .add-ranking-sport,
        .add-ranking-type,
        .add-ranking-location,
        .add-ranking-gender,
        .add-ranking-result{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 45px;
            svg{
                font-size: 1.2rem;
                color: var(--text);
            }
        }
        .add-ranking-wrapper{
            display: flex;
            align-items: center;
            .add-ranking-wrapper-text{
                height: 10px;
                width: 130px;
                background-color: var(--middleGray);
                margin-left: 10px;
            }
        }
        .add-ranking-type{
            margin-left: 15px;
        }
        .add-ranking-location{
            margin-left: 30px;
        }
        .add-ranking-gender{
            margin-left: 45px;
        }
        .add-ranking-result{
            margin-left: 60px;
        }
    }
}


.article{
    margin-top: 30px;
    .article-header{
        background-color: var(--red);
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 0 10px;
        font-size: 1.3rem;
        border-top: 1px solid var(--bg);
        .article-header-buttons{
            svg{
                margin-right: 10px;
            }
        }
    }
    .article-content{
        height: 220px;
        overflow: hidden;
        background-color: var(--bg);
        padding: 15px;
        position: relative;
        .article-content-title{
            height: 20px;
            width: 100%;
            background-color: var(--light);
            margin-bottom: 5px;
        }
        .article-content-sub{
            height: 10px;
            width: 40%;
            background-color: var(--light);
            margin-bottom: 5px;
        }
        .article-content-img{
            background-color: var(--light);
            width: 100%;
            height: 100px;
            margin-top: 15px;
        }
    }
}


.tutorial-bookmark{
    position: relative;
    .finger{
        position: absolute;
        bottom: 225px;
        left: 5px;
        animation: reveal-finger-bookmarks 1s ease-in;
    }
    .bookmark-img{
        margin-top: 30px;
    }
    p{
        text-align: center;
    }
    
}

.tutorial-search{
    position: relative;
    .finger{
        position: absolute;
        bottom: 225px;
        right: -35px;
        animation: reveal-finger-bookmarks 1s ease-in;
    }
    p{
        text-align: center;
    }
}

.tutorial-sections{
    position: relative;
    .dnd-x-finger,
    .dnd-y-finger,
    .finger{
        position: absolute;
        animation: reveal-finger-bookmarks 1s ease-in;
    }
    .dnd-x-finger{
        top: 60px;
        right: calc(50% - 50px);
    }
    .dnd-y-finger{
        top: 160px;
        right: calc(50% - 50px);
    }
    .finger{
        top: 64px;
        right: -22px;
    }
    .first,
    .second,
    .third{
        min-height: 450px;
    }
    p{
        text-align: center;
    }
}

.tutorial-live{
    position: relative;
    .first, .second{
        min-height: 450px;
    }
    .first .dnd-finger{
        bottom: 48px;
    }
    .finger,
    .dnd-finger,
    .hold-finger{
        position: absolute;
    }
    .finger{
        bottom: 220px;
        left: -23px;
        animation: reveal-finger-live 1s ease-in;
    }
    .finger-addLive{
        right: -35px;
        left: initial;
        bottom: 5px;
        animation: reveal-finger-liveAdd 1s ease-in;
    }
    .dnd-finger{
        bottom: 66px;
        right: 0;
    }

    .organize-live-1,
    .organize-live-2{
        bottom: 100px;
        right: calc(50% - 50px);
    }

    .calendar{
        padding: 0;
    }
    p{
        text-align: center;
    }
    .live-access{
        margin-top: 30px;
        .live-access-header{
            background-color: var(--red);
            height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;
            font-size: 1.3rem;
            padding: 0 10px;
            border-top: 1px solid var(--bg);
            .live-access-header-title{
                font-weight: $font-semibold;
                font-size: 1rem;
            }
        }
        li{
            &:nth-last-child(2){
                display: none;
            }
        }
        .live-access-body{
            background-color: var(--bg);
            height: 200px;
            .live-access-search{
                display: flex;
                align-items: center;
                padding: 10px;
                border-bottom: 1px solid var(--light);
                span{
                    color: var(--gray);
                    margin-left: 5px;
                }
                .live-access-search-val{
                    width: 50%;
                    height: 10px;
                    background-color: var(--light);
                }
                svg{
                    color: var(--gray);
                }
            }
            .live-access-item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 10px;
                border-bottom: 1px solid var(--light);
                .live-access-item-text{
                    height: 15px;
                    width: 50%;
                    background-color: var(--light);
                }
                svg{
                    color: var(--text);
                }
            }
            .live-access-item-dnd{
                border-bottom: 0;
            }
            .live-access-body-add{
                display: flex;
                justify-content: space-between;
                padding: 10px;
                color: var(--text);
                align-items: center;    
                .live-access-body-add-text{
                    width: 70%;
                    height: 15px;
                    background-color: var(--light);
                }
            }
            .live-access-wrapper{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 10px;
                margin-right: 10px;
                svg{
                    color: var(--gray);
                }
            }
            .live-access-competition{
                width: 70%;
                height: 15px;
                background-color: var(--light);
                margin-bottom: 10px;
                margin-top: 10px;
            }
        }
        .live-access-bodyAdd{
            height: 100px;
            .live-access-body-add{
                svg{
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@keyframes reveal-finger-liveAdd {
	from {
        bottom: -60px;
        opacity: 0;
	}
	to {
        bottom: 5px;
        opacity: 1;
	}
}

@keyframes reveal-finger-live {
	from {
        bottom: 180px;
        opacity: 0;
	}
	to {
        bottom: 220px;
        opacity: 1;
	}
}

@keyframes reveal-finger-bookmarks {
	from {
        bottom: 190px;
        opacity: 0;
	}
	to {
        bottom: 225px;
        opacity: 1;
	}
}

@keyframes reveal-finger-rankings {
	from {
        bottom: 0;
        opacity: 0;
	}
	to {
        bottom: 98px;
        opacity: 1;
	}
}

@keyframes setOpacity {
    0% {
        opacity: 0;
	}
	50% {
        opacity: 0;
	}
	100% {
        opacity: 1;
    }
}

@keyframes modal {
	from {
        top: 100px;
        opacity: 0;
	}
	to {
        top: 0;
        opacity: 1;
	}
}

@keyframes reveal-second {
    0% {
        opacity: 0;
        bottom: 150px;
	}
	50% {
        bottom: 150px;
        opacity: 0;
	}
	100% {
        bottom: 255px;
        opacity: 1;
    }

}

@keyframes reveal-opacity {
	from {
        bottom: -110px;
        opacity: 0;
	}
	to {

    bottom: -65px;
        opacity: 1;
	}
}

@keyframes tap {
	0% {
		transform: rotateX(0deg);
	}
	10% {
		transform: rotateX(12.5deg);
	}
	25% {
		transform: rotateX(25deg);
	}
}

@keyframes tap-circle {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	75% {
		transform: scale(1.05);
		opacity: .6;
	}
	80% {
		transform: scale(1);
		opacity: .5;
	}
}

@keyframes hold {
	0% {
		transform: rotateX(0deg);
	}
	10% {
		transform: rotateX(12.5deg);
	}
	30% {
		transform: rotateX(25deg);
	}
	80% {
		transform: rotateX(25deg);
	}
}


@keyframes swipe-y {
	0% {
		transform: translateY(0px);
	}
	25% {
		transform: translateY(50px) rotateZ(-10deg);
	}
	50% {
		transform: translateY(0px);
	}
	75% {
		transform: translateY(-50px) rotateZ(10deg);
	}
}

@keyframes swipe-x {
	0% {
		transform: translateX(0px);
	}
	25% {
		transform: translateX(50px) rotateZ(10deg);
	}
	50% {
		transform: translateX(0px);
	}
	75% {
		transform: translateX(-50px) rotateZ(-10deg);
	}
}
.loader {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em auto;
  height: auto;
}

.loader div {
  color: #46A546;
  height: auto;
}

.jde .loader div {
  color: #009DDF;
}

.abosport .loader div {
  color: #d60b13;
}

.CircularProgress, .loader svg, .loader circle{
  height: auto;
}

@import "../../../styles/config";

.container{
    border-radius: 9px;
    padding: 0px 9px;
    margin: 11px 10px;
    font-size: 0.8rem;
    font-weight: bold;
    &__now{
        background-color: var(--link);
        color: white;
    }
    &__next{
        background-color: var(--dark);
        color: var(--light);
    }
    &__past{
        background-color: gray;
        color: var(--light);
    }
}
@import "../../../styles/config";

.container{
    display: flex;
    flex-direction: row;
    background: var(--bg);
    @media screen and (min-width: $max-770){
        background: var(--bg-desktop-item);
    }
    &:last-child{
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    &__time{
        color: var(--text);
        width: 60px;
        text-align: right;
        padding-right: 10px;
        font-size: 0.9rem;
        font-weight: $font-semibold;
        padding-top: 2px;
    }
    &__svg{
        display: flex;
        flex-direction: row;
        position: relative;
        min-height: 100px;
        &::after{
            content:"";
            position: absolute;
            z-index: 0;
            top: 0;
            bottom: 0;
            left: 50%;
            border-left: 2px dotted  var(--text);
            transform: translate(-50%);
        }
    }
    &__low{
        min-height: 100px;
        svg{
            border: 10px solid white;
            border-radius: 50px;
            padding: 5px;
            width: 30px;
            height: 30px;
            background-color: $color-gray;
            color: $color-gray;
            z-index: 1;
        } 
    }
    &__medium{
        min-height: 100px;
        svg{
            border: 2px solid var(--red);
            border-radius: 50px;
            padding: 5px;
            width: 30px;
            height: 30px;
            background-color: white;
            color: $color-dark;
            z-index: 1;
        } 
    }
    &__high{
        min-height: 100px;
        svg{
            border: 0px;
            border-radius: 50px;
            padding: 5px;
            width: 30px;
            height: 30px;
            background-color: $color-dark;
            color: white;
            z-index: 1;
        } 
    }
    &__action{
        margin-left: 10px;
        padding-top: 2px;
        color: var(--text);
        &__fact{
            font-weight: $font-semibold;
            font-size: 0.9rem;
            // text-transform: uppercase;
        }
        &__team{
            color: $color-gray;
            font-size: 0.9rem;
        }
    }
    &__icon{
        margin-left: auto;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        @media screen and (min-width: $max-770){
            margin-right: 50px;
            cursor: pointer;
            opacity: 0.9;
            will-change: opacity;
            transition: opacity 0.2s ease-in-out;
        }
        svg{
            font-size: 2rem;
        }
        span{
            font-size: 0.8rem;
        }
    }
    &__video{
        position: relative;
        // padding-bottom: 45.25%;
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 30px;
        margin-right: 20px;
    }
}
@import "../../../styles/config";

.container{
    position: relative;
    font-family: $font-text;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid var(--red);
    @media screen and (min-width: $max-770){
        background: var(--bg-desktop-item);
        margin-bottom: 0px;
        border-bottom: 0px;
        margin-top: 0;
    }
    span{
        opacity: 0.7;
        padding-top: 3px;
    }
    &__body{
        display: flex;
        flex-direction: column;
        background-color: var(--red);
        padding: 15px;
        color: #fff;
        padding-top: 50px;
        &__iframe{
            padding-top: 15px;
        }
        @media screen and (min-width: $max-770){
            padding-top: 20px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        &__timeline{
            width: 100%;
            margin-top: 10px;
            &__line{
                display: flex;
                justify-content: space-between;
                border: 1px solid rgba(255,255,255,0.5);
                &__period{
                    &__ongoing{
                        animation: animatedBackground 10s linear infinite;
                    }
                    height: 10px;
                    display: block;
                    opacity: 1 !important;
                    background-color: var(--red);
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23ffffff' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
                    border-right: 3px solid white;
                    &:last-child{
                        border-right: none;
                    }
                }
            }
            &__facts{
                position: relative;
                &__icon{
                    position: absolute;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                } 
            }
        }
        &__date, 
        &__place{
            font-size: 0.9rem;
        }
        &__date{
            font-weight: $font-semibold;
        }
        &__division{
            padding-top: 7px;
            letter-spacing: 0.2px;
            font-size: 0.8rem;
        }
        &__scores{
            margin-top: 10px;
            margin-bottom: 30px;
        }
        &__teams{
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 5px;
            &__goal{
                font-size: 0.7rem;
                display: flex;
                flex-wrap: wrap;
                width: 45%;
                span{
                    &::after{
                        content: ',';
                        margin-right: 5px;
    
                    }
                    &:last-child{
                        &::after{
                            content: '';
                            margin-right: 0;
        
                        }
                    }
                }
                &:first-child{
                    justify-content: flex-start;
                    padding-right: 5px;
                }
                &:last-child{
                    padding-left: 5px;
                    justify-content: flex-end;
                }
            }
            &__team{
                width: 50%;
                font-size: 1.2rem;
                display: flex;
                align-items: center;
                &:first-child{
                    justify-content: flex-end;
                    padding-right: 5px;
                }
                &:last-child{
                    padding-left: 5px;
                }
                &__smallTeamName{
                    font-weight: $font-semibold;
                    margin: 0;
                    font-size: 1.1rem;
                }
                &__mediumTeamName{
                    font-weight: $font-semibold;
                    margin: 0;
                    font-size: 0.9rem;
                }
                &__bigTeamName{
                    font-weight: $font-semibold;
                    margin: 0;
                    font-size: 0.7rem;
                }
                &__img{
                    border-radius: 20px;
                    background: var(--teamLogo);
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 7px;
                    margin-left: 7px;
                    padding: 5px;
                    svg{
                        color: var(--red);
                    }
                }
            }
        }
    }
    &__matchSheet,
    &__teamsTable,
    &__matchFacts{
        h4{
            margin: 0;
            border-top: 2px solid var(--red);
            padding: 10px 0;
            text-align: center;
            position: relative;
            svg{
                position: absolute;
                right: 15px;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        } 
    }
}

@keyframes blinker {
    50% {
      opacity: 0;
    }
  }

@keyframes animatedBackground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 100% 0;
    }
}
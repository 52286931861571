@import "../../../styles/config";

.open_container{
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 20;
    height: 100vh;
    top: 0;
    display: flex;
    justify-content: center;
    left: 0;
    display: block;
    &__closeArea{
        background: black;
        height: 100vh;
    }
    &__closeButton{
        color: white;
        font-size: 2rem;
        position: absolute;
        top: 15px;
        right: 15px;
        @media screen and (min-width: $max-770){
            cursor: pointer;
        }
    }
    &__content{
        position: absolute;
        margin: auto;
        top: 50%; left: 50%;
        width: 100%;
        transform: translate(-50%,-50%);
    }
}

.close_container{
    display: none;
    &__closeArea{}
    &__content{}
}
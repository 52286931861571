@import "../../styles/config";

.container{
    margin-bottom: 50px;
    position: relative;
    @media screen and (min-width: $max-770){
        background-color: var(--bg-desktop-item);
        border-radius: 5px;
        min-height: 300px;
    }
    &__calendar{
        @media screen and (max-width: $max-tablet){
            position: fixed;
            top: 50px;
        }
        @media screen and (min-width: $max-770){
            position: sticky;
            top: 50px;
        }
        &__iframe{
            top: 0;
        }
    }

    &__header{
        background-color: var(--light);
        display: flex;
        padding: 5px 10px;
        justify-content: space-between;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-size: 0.8rem;
        position: sticky;
        z-index: 1;
        top: 105px;
        color: var(--text);
        @media screen and (min-width: $max-770){
            background-color: var(--middleLight);
        }
        &__iframe{
            top: 50px;
        }
        &__resume{
            font-weight: $font-semibold;
            span{
                color: var(--link);
                text-decoration: underline;
                cursor: pointer;
            }
            b{
                &:after{
                    content: ".";
                    margin-left: 3px;
                    margin-right: 3px;
                }
            }
        }
    }
    &__menu{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border-bottom: 1px solid var(--light);
        @media screen and (min-width: $max-770){
            position: sticky;
            top: 100px;
            border-bottom: none;

        }
        &__left{
            display: flex;
            align-items: center;
            p{
                margin: 0;
                text-transform: uppercase;
                font-size: 0.8rem;
                letter-spacing: 0.2px;
                font-weight: $font-semibold;
            }
            span{
                color: var(--red);
                font-size: 0.8rem;
                margin-left: 5px;
                font-weight: $font-semibold;
                text-decoration: underline;
            }
        }
        &__multi{
            background-color: var(--text);
            color: var(--bg);
            border-radius: 7px;
            padding: 0 5px;
            font-size: 0.9rem;
            font-weight: $font-semibold;
            text-transform: inherit;
            margin-top: 5px;
        }
    }
    &__division{
        font-weight: $font-semibold;
        font-family: $font-text;
        font-size: 0.9rem;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        color: var(--text);
        padding: 10px;
        border-bottom: 1px solid var(--light);
    }
    &__competition{
        &__title{
            background-color: var(--light);
            font-size: 0.8rem;
            padding: 5px 10px;
            display: flex;
            justify-content: space-between;
            @media screen and (min-width: $max-770){
                background-color: $color-middle-light;; 
            }
            &__division{
                b{
                    text-transform: uppercase;
                    font-weight: $font-semibold;
                    letter-spacing: 0.2px;
                }
                span{
                    margin-left: 5px;
                    &::before{
                        content:'.';
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    &__loader{
        margin-top: 150px;
        padding-bottom: 50px;
    }
    &__form{
        position: relative;
        width: 100%;
        margin-right: 10px;
        &::after {
            content: 'changer ▾';
            pointer-events: none;
            text-transform: lowercase;
            position: absolute;
            // right: 10px;
            right: 3px;
            top: 2px;
            font-size: 0.8rem;
            color: var(--text);
            @media screen and (max-width: $max-tablet){
                top: 2px; 
            }
        }
        &__select{
            -moz-appearance: none;
            -webkit-appearance: none;
            background-color: var(--bg);
            border: none;
            border-radius: 0;
            cursor: pointer;
            width: 100%;
            outline: none;
            font-weight: $font-semibold;
            font-family: $font-text;
            font-size: 0.9rem;
            text-transform: uppercase;
            letter-spacing: 0.2px;
            color: var(--text);
            @media screen and (max-width: $max-tablet){
                letter-spacing: 0.2px;
            }
            @media screen and (min-width: $max-770){
                font-weight: inherit;
                background-color: var(--bg-desktop-item);
            }
            &:focus {
                outline: none;
            }
            &::-ms-expand {
                display: none;
            }
            &__option{
                color: var(--text);
                font-size: 1rem;
                padding-left: 10px;
                padding-right: 10px;
                text-transform: initial;
            }
        }
    }
    &__noMatches{
        justify-content: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 40px;
        color: var(--text);
        svg{
            color: $color-gray;
            font-size: 2rem;
        }
        p{
            font-size: 0.9rem;
        }
    }
    &__noFacts{
        display: flex;
        justify-content: center;
        flex-direction: column;
        p{
            text-transform: uppercase;
            letter-spacing: 0.2px;
            font-weight: $font-semibold;
        }
    }
}